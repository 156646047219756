import { Button, Stack, Typography, Box } from "@mui/material";
import React, { useEffect } from "react";
import { Modal } from 'components/common/Dialog';
import { getToolBrandImageRender } from "components/Activity.components/common";
import { getFaviconByDomain } from "utils/urlParsers";

interface TitlesListingWidgetProps {
  titles: string[];
  numberOfContactsFound: number;
  accountName: string;
}

const DISPLAY_THRESHOLD = 5;
const googleFavicon = getFaviconByDomain('google.com');
const TitlesListingWidget = ({ accountName, titles, numberOfContactsFound }: TitlesListingWidgetProps) => {
  const [open, setOpen] = React.useState(false);

  const onLookupTitle = (title: string) => {
    window.open(`https://www.google.com/search?q=site:linkedin.com "${title}" ${accountName} -hiring`, '_blank');
  };

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  useEffect(() => {
    if (numberOfContactsFound > DISPLAY_THRESHOLD) {
      onOpen();
    }
    return () => {
      onClose();
    };
  }, [numberOfContactsFound]);

  if (numberOfContactsFound >= DISPLAY_THRESHOLD) {
    return null;
  }

  getToolBrandImageRender
  return (
    <Stack spacing={2}>
      <Typography>
        <span>Here's what the agent worker looked for based on the </span>
        <Button onClick={onOpen}>job titles</Button>
        <span> suitable for the account.</span>
      </Typography>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        containerSx={{
          maxWidth: '700px',
        }}
      >
        <Typography variant="h5" id="modal-modal-title" sx={{ mb: 2 }}>
          Here are the titles that the Person Recommender looked for:
        </Typography>
        <Box
          sx={{
            p: 3,
            maxHeight: '50vh',
            overflowY: 'auto',
            backgroundColor: 'white',
          }}
        >
          <Stack spacing={2}>
            {titles.map((title) => (
              <Stack
                key={title}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderBottom: '1px solid #e6e6e6',
                  pb: 1,
                  mb: 1,
                }}
              >
                <Typography variant="body1">{title}</Typography>
                <Button
                  size="small"
                  onClick={() => onLookupTitle(title)}
                  sx={{
                    textTransform: 'none',
                    fontSize: '12px',
                    color: '#333333',
                  }}
                  startIcon={<img src={googleFavicon} alt="Google" />}
                >
                  <span>
                    Search on Google
                  </span>
                </Button>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default TitlesListingWidget;
